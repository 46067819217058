<script>
import Layout from "../../layouts/main";
import QALayout from "../../layouts/QALayout.vue";
import PageHeader from "@/components/Page-header";
import ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import userService from "@/helpers/userService";
import errorHandler from '@/helpers/errorHandler'
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  page: {
    title: "Trials list",
  },
  head() {
    return {
      title: `Trials`,
    };
  },
  components: {
    Layout,
    PageHeader,
    ConfirmActionDialog,
    QALayout,
    ExportExcel
  },
  async created() {
    if(userService.methods.userRole() == 0){
      await this.$store.dispatch("quiz/getAllQuizes",'trial').then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
    }else{
      // TODO:: Quizes by year here
      // await this.$store.dispatch("chapter/getAllChaptersByYear", userService.methods.userYear()).then( ()=>{}).catch( err => {
      //   errorHandler.methods.errorMessage(err);
      // });
    }
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    quizes() {
      return this.$store.getters["quiz/allQuizes"];
    },
    rows() {
      return this.quizes.length;
    }
  },
  methods: {
    RemoveRecord:function(){
     this.$store.dispatch("quiz/deleteQuiz",this.recordId).then((token)=>{
      errorHandler.methods.successful('Item deleted successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
    stopChance:function(){
     this.$store.dispatch("quiz/stopChance",this.recordId).then((token)=>{
      errorHandler.methods.successful('Quiz stoped chances successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('stopRecord')
    },
     showDeleteModal(id) {
      this.$bvModal.show('deleteRecord')
      this.recordId = id
    },
     showDeleteModal2(id) {
      this.$bvModal.show('stopRecord')
      this.recordId = id
    },
    viewProfile(record,index){
      this.$router.push('/quizes/' + record.id + '/details')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async openChance(item){
      await this.$store.dispatch('quiz/editQuiz',{
            year : item.educational_year.id,
            id : item.id,
            chapter_id : item.chapter.id,
            name : item.name,
            quiz_type : item.quiz_type,
            chance : true,
          }).then(async ()=>{
        await this.$store.dispatch('quiz/openChance',item.id);
        errorHandler.methods.successful('Chance is open successfully');
      }).catch(err =>{
        errorHandler.methods.errorMessage(err);
      })
    }
  },
  data: () => ({    totalRows:0,
    recordId: null,
    title: "Trials List",
    items: [
      {
        text: "Work Center",
        href: "/"
      },
      {
        text: "Trials",
        href: "/trials"
      }
    ],
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "number",
    sortDesc: false,
    filterOn: ["number","name","description"],
    fields: [
      {
        key: "name",
        label: "Name",
        sortable: true,
        selected: true
      },
      {
        key: "educational_year",
        label: "Stage",
        sortable: true,
        selected: true
      },
      {
        key: "chapter",
        label: "Lecture",
        sortable: true,
        selected: true
      },
      {
        key: "grade",
        label: "Full mark",
        sortable: true,
        selected: true
      },
      {
        key: "grade",
        label: "Full mark",
        sortable: true,
        selected: true
      },
      {
        key: "give_chance",
        label: "Chance",
        sortable: true,
        selected: true
      },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
  })
};
</script>

<template>
  <Layout>
    <QALayout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <router-link to="/quizes/create/trial" v-if="$store.getters['user/role'] == 0">
                    <b-button variant="success" class="btn-sm mr-2">
                      <i class="mdi mdi-plus"></i>
                      Add Trial
                    </b-button>
                  </router-link>
                  <export-excel :jsonData="quizes" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="quizes"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="viewProfile"
            >
            <template v-slot:cell(educational_year)="data">
              {{ data.item.educational_year.number + '-' + data.item.educational_year.description }}
            </template>
            <template v-slot:cell(chapter)="data">
              {{ data.item.chapter.name }}
            </template>
            <template v-slot:cell(give_chance)="data">
              <b-badge v-if="data.item.give_chance" variant="success" pill>Yes</b-badge>
              <b-badge v-else variant="danger" pill>No</b-badge>
            </template>
          <template v-slot:cell(action)="data" v-if="$store.getters['user/role'] == 0">
                <b-dropdown
                  class="btn-icon-onl"
                  right
                  toggle-class="arrow-none btn-light btn-sm"
                >
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal iconGray"></i>
                  </template>

                  <b-dropdown-item
                    :to="'/quizes/' + data.item.id + '/edit'"
                  >
                    <i
                      class="mdi mdi-pencil mr-2 text-muted font-18 vertical-middle iconGray"
                    ></i
                    >Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                 v-on:click="showDeleteModal(data.item.id)"
                  ><i
                    class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Delete
                  </b-dropdown-item>
                  <b-dropdown-item
                  v-if="data.item.give_chance"
                 v-on:click="showDeleteModal2(data.item.id)"
                  ><i
                    class="mdi mdi-close-octagon mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Close Chance
                  </b-dropdown-item>
                  <b-dropdown-item
                  v-else
                    v-on:click="openChance(data.item)"
                  ><i
                    class="mdi mdi-check mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Open Chance
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="deleteRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
        </template>
    </b-modal>
    <b-modal id="stopRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="stopChance()">Stop</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('stopRecord')">Cancel</b-button>
        </template>
    </b-modal>
  </QALayout>
  </Layout>
</template>
